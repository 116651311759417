<template>
  <div class="container" style="height: 100%">
    <div class="menu">
      <el-menu
        unique-opened
        background-color="#2d3a4b"
        text-color="#fff"
        @select="select"
        :defaultActive="$route.path"
      >
        <el-submenu
          :index="item.id"
          v-for="item in menuList"
          v-bind:key="item.id"
        >
          <template slot="title">
            <i v-bind:class="item.menuicon"></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              :index="child.childid"
              v-for="child in item.child"
              v-bind:key="child.childid"
              v-on:click="onMenuClick(child)"
              >{{ child.name }}</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
    <div class="content">
      <div class="header">
        <div style="flex: 1">
          <el-breadcrumb class="app-breadcrumb" separator="/">
            <el-breadcrumb-item
              v-for="(item, index) in levelList"
              :key="item.title"
            >
              <span v-if="index == levelList.length - 1">{{ item.title }}</span>
              <a v-else @click.prevent="handleLink(item)">{{ item.title }}</a>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-dropdown trigger="hover" @command="handleCommand">
          <span class="el-dropdown-link">
            <div style="display: flex; align-items: center">
              <el-avatar
                size="large"
                :src="avatarUrl"
                style="margin-right: 10px"
              ></el-avatar>
              {{ userfullname
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">个人中心</el-dropdown-item>
            <el-dropdown-item command="2">修改密码</el-dropdown-item>
            <el-dropdown-item command="3" divided>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="body">
        <router-view></router-view>
      </div>
      <div class="footer">
        <p>智慧智能AI心理健康分析测评系统</p>
        <p>江苏省智慧智能软件科技有限公司</p>
      </div>
    </div>

    <el-dialog
      append-to-body
      title="账户详情"
      :visible.sync="viewDetailDialog"
      v-if="viewDetailDialog"
      width="700px"
    >
      <ViewUserDetails :userData="loginData"></ViewUserDetails>
    </el-dialog>
  </div>
</template>

<script>
import path from "path";
import router from "../router";
import api from "../api";
import ViewUserDetails from "../components/ViewUserDetails";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: { ViewUserDetails },
  computed: {
    ...mapState({
      defaultActive: (state) => state.permission.defaultActive,
    }),
  },
  data() {
    return {
      levelList: [],
      menuList: [],
      avatarUrl:
        "http://asai-prod.oss-cn-shanghai.aliyuncs.com/resources/website/websiteicon.jpg",
      userfullname: "",
      loginData: null,
      viewDetailDialog: false,
    };
  },

  created() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    this.userfullname = this.loginData.userfullname;
    let pathList = router.options.routes[4].children;
    let urls = this.loginData.urls;
    let routerList = []; //展示的二级菜单路由
    pathList.map((e) => {
      if (e.path.indexOf("/dashboard") != "-1") {
        e.path = e.path.slice(10);
      }
      let item = urls.find((v) => {
        return e.path == v.url;
      });
      if (item) routerList.push(item);
    });
    // console.log(routerList)
    this.getBreadcrumb();
    this.initMenuList(urls, routerList);
  },
  watch: {
    $route(newValue) {
      console.log("newValue", newValue);
      this.getBreadcrumb();
    },
  },
  methods: {
    getBreadcrumb() {
      const timer = setTimeout(() => {
        const levelList = [];
        this.$route.matched.forEach((route) => {
          // console.log(route);
          if (route.name) {
            levelList.push({
              title: route.name,
              path: route.path,
            });
          }
        });
        if (
          levelList &&
          levelList.length > 0 &&
          levelList[0].title !== "首页"
        ) {
          levelList.unshift({ title: "首页", path: "/" });
        }
        this.levelList = levelList;
        // console.log(this.levelList);
        clearTimeout(timer);
      }, 100);
    },
    /**
     * @description 点击面包屑的事件
     * @param {object} item 路由信息
     */
    handleLink(item) {
      const mPath = item.path;
      // console.log(item);
      const firstChild = this.findFirstChild(
        mPath,
        this.$store.state.permission.routes
      );
      // console.log(firstChild);
      // return false
      if (firstChild) {
        this.$router.push(path.resolve(item.path, firstChild.path));
      } else {
        this.$router.push(mPath);
      }
    },
    /**
     * @description 在菜单中找到对应路由的第一个子菜单
     * @param {string} mPath 需要寻找的路由
     * @param {array} routes 寻找的范围
     * @return {object} 对应路由的第一个子菜单
     */
    findFirstChild(mPath, routes) {
      let firstChild = null;
      if (!routes) {
        return null;
      }
      for (const route of routes) {
        if (route.path === mPath) {
          if (route.children && route.children.length > 0) {
            return route.children[0];
          } else {
            return null;
          }
        } else if (route.children && route.children.length > 0) {
          firstChild = this.findFirstChild(mPath, route.children);
        }
      }
      return firstChild;
    },
    initMenuList(list, routerList) {
      for (let index in list) {
        let item = list[index];
        //顶级菜单
        if (item.menulevel === "1") {
          //展示的一级菜单路由
          this.menuList.push({
            id: item.id,
            pid: item.pid,
            name: item.name,
            url: item.url,
            menuicon: item.menuicon,
            menulevel: item.menulevel,
            ordernum: item.ordernum,
            child: getMenulevelList(routerList, item.id),
          });
        }
      }
    },
    select(index) {
      console.log(index);
      this.$store.state.permission.defaultActive = index;
    },
    onMenuClick(v) {
      router.push("/dashboard" + v.url);
    },
    handleCommand(command) {
      switch (command) {
        case "1":
          this.viewDetailDialog = true;
          break;
        case "2":
          this.$prompt("请输入新密码", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            inputPattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            inputErrorMessage: "密码应包含数字和英文，长度6-20位",
          }).then(({ value }) => {
            api
              .updateMUser({
                categorycode: this.loginData.categorycode,
                pwd: this.$md5(value),
                userlevel: this.loginData.userlevel,
              })
              .then((res) => {
                if (res.data.success === "1") {
                  this.$message({
                    showClose: true,
                    message: "修改成功，请重新登录",
                    type: "success",
                  });
                  localStorage.removeItem("logindata");
                  this.$Cookies.remove("asai_id");
                  router.push("/Login");
                } else {
                  this.$message({
                    showClose: true,
                    message: res.data.errorMsg,
                    type: "warning",
                  });
                }
              });
          });
          break;
        case "3":
          this.$confirm("确定要退出登录吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            localStorage.removeItem("logindata");
            this.$Cookies.remove("asai_id");
            router.push("/Login");
          });
          break;
      }
    },
  },
};

/**
 * 获取父菜单下的子菜单
 * @param list 所有菜单
 * @param id 父菜单id
 * @returns {[]} 子菜单列表
 */
function getMenulevelList(list, id) {
  let result = [];
  for (let index in list) {
    let item = list[index];
    if (item.pid === id) {
      result.push({
        childid: "/dashboard" + item.url,
        id: item.id,
        pid: item.pid,
        name: item.name,
        url: item.url,
        menulevel: item.menulevel,
        ordernum: item.ordernum,
      });
    }
  }
  return result;
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
}
.page-component__scroll .el-scrollbar__wrap {
  overflow-x: hidden;
}
.menu {
  position: fixed;
  height: 100%;
  width: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #2d3a4b;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 210px;
  z-index: 999;
  background: white;
  min-width: 860px;
  position: relative;
}
.header {
  min-height: 65px;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0 1px 4px #c0c4cc;
  z-index: 999;
}
.body {
  /*overflow-y: scroll;*/
  /*height: 100%;*/
  min-height: 960px;
}
.footer {
  margin-top: 100px;
  text-align: center;
  font-size: 10px;
  color: #909399;
  background: #f2f6fc;
}
.el-dropdown {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: 500;
}
</style>